import NextLink from 'next/link';

import { BreadcrumbLinkProps } from './BreadcrumbLink.interfaces';

const BreadcrumbLink = ({ children, href = '/' }: BreadcrumbLinkProps) => {
  return (
    <NextLink href={href}>
      <span className="color-black flex mb-0">{children}</span>
    </NextLink>
  );
};

export { BreadcrumbLink };
