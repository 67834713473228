import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { Button } from '../Button';
import { CaretDownIcon } from '../Icons';
import { Link } from '../Link';
import { StatusIcon } from '../StatusIcon';

import { ContentNavigationLinkProps } from './ContentNavigationLink.interfaces';

import { getValidClassNames } from '../../utils';

const ContentNavigationLink = ({
  children,
  href,
  isActive,
  isCompleted = false,
  isDisabled = false,
  isInProgress,
  isSubMenuOpenOnDefault = false,
  subMenu,
  text,
}: React.PropsWithChildren<ContentNavigationLinkProps>) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

  const toggleSubMenu = () => setIsSubMenuOpen(!isSubMenuOpen);

  useEffect(() => {
    setIsSubMenuOpen(isSubMenuOpenOnDefault);
  }, [isSubMenuOpenOnDefault]);

  return (
    <li className="mb-2 ml-0">
      <div
        className={cx('flex mb border border-athens rounded-sm', {
          'bg-primary border-primary': isActive ?? isSubMenuOpen,
          'hover:bg-grey-50': !isDisabled && !isActive,
          'bg-grey-50': isDisabled,
        })}
      >
        <Link className="flex flex-1 items-center relative px-3 py-3 mb-0" href={href} isDisabled={isDisabled}>
          <div className="mr-3 mt-0-5">
            <StatusIcon isActive={isActive} isCompleted={isCompleted} isInProgress={isInProgress} />
          </div>
          <div className="flex-1">
            <span
              className={getValidClassNames({
                'block m-0 line-1.2': true,
                'color-secondary': !isActive,
                'color-white': Boolean(isActive),
              })}
            >
              {text}
            </span>
            {children}
          </div>
        </Link>
        {subMenu && (
          <Button aria-expanded={isSubMenuOpen} className="flex items-center pointer px-3 py-3" aria-haspopup onPress={toggleSubMenu}>
            <div
              className={getValidClassNames({
                'items-center flex h-5 justify-center ml-2 rounded-full transition-200 w-5': true,
                'rotate-180': isSubMenuOpen,
                'bg-white color-primary': Boolean(isActive),
                'bg-primary color-white': Boolean(!isActive),
              })}
            >
              <span className="sr-only">{isActive ? 'Close menu' : 'Open menu'}</span>
              <CaretDownIcon />
            </div>
          </Button>
        )}
      </div>
      {isSubMenuOpen && subMenu}
    </li>
  );
};

export { ContentNavigationLink };
